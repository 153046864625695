<template>
  <a-drawer
    title="充值明细"
    width="1000"
    :visible="visible"
    :closable="false"
    @close="onClose"
  >
    <!-- 列表 -->
    <a-table
      class="table-view"
      :data-source="dataSource"
      :columns="columns"
      :row-key="record => record.id"
      :pagination="pagination"
      @change="handleTableChange"
    >
      <!-- 自定义行内容 -->
      <template #bodyCell="{ column, record }">
        <!-- 推广ID -->
        <template v-if="column.key === 'adv_id'">
          {{  record.adv_id || '-' }}
        </template>
        <!-- 购买页面 -->
        <template v-if="column.key === 'buy_place'">
          <span>{{ record[column.key] }}{{ record.eq_number !== 0 ? `【第${record.eq_number}集】` : '' }}</span>
        </template>
      </template>
    </a-table>
    <!-- 加载 -->
    <Loading :loading="isLoading"></Loading>
  </a-drawer>
</template>

<script setup>
import { onBeforeMount, ref, reactive } from 'vue'
import { message } from 'ant-design-vue'
import dayjs from 'dayjs'

import * as Ex from "@netjoy/excelex"
import { purchaseList } from '@/api/operate'
import Loading from '@/components/Loading'
import Pub from '@/utils/public'

// 显示
let visible = ref(false)
// 加载
let isLoading = ref(false)
// 当前数据
let data = ref({})
// 日期范围
let dateRange = ref([dayjs().subtract(30, 'day'), dayjs()])
// 日期format
const dateFormat = 'YYYY-MM-DD'
// 数据源
let dataSource = ref([])
// 分页信息
let pagination = reactive({
  total: 0,
  current: 1,
  pageSize: 10,
  showSizeChanger: true
})
// 列数据源
let columns = ref([
  {
    title: '购买时间',
    dataIndex: 'pay_success_time',
    key: 'pay_success_time'
  },
  {
    title: 'UID',
    dataIndex: 'user_id',
    key: 'user_id'
  },
  {
    title: '推广ID',
    dataIndex: 'adv_id',
    key: 'adv_id'
  },
  {
    title: '购买内容',
    dataIndex: 'charge_content',
    key: 'charge_content'
  },
  {
    title: '金额',
    dataIndex: 'total_amount',
    key: 'total_amount'
  },
  {
    title: '购买页面',
    dataIndex: 'buy_place',
    key: 'buy_place'
  }
])

// 显示
function showDrawer (record) {
  // 内容
  data.value = record
  data.value.drama_name = record.project_drama_name || '-'
  data.value.push_date = record.send_time
  // 显示
  visible.value = true
  // 获取数据详情列表
  getList()
}

// 关闭
function onClose () {
  if (!isLoading.value) {
    pagination.current = 1
    visible.value = false
  }
}

// 分页处理
function handleTableChange (p, filters, sorter) {
  pagination.current = p.current
  pagination.pageSize = p.pageSize
  getList()
}

// 获取数据详情列表
function getList () {
  isLoading.value = true
  const params = {
    // start_at: data.value.send_time,
    // end_at: data.value.send_time,
    qy_id: data.value.id,
    page: pagination.current,
    page_size: pagination.pageSize
  }
  purchaseList(params).then(res => {
    isLoading.value = false
    const { code, data, msg } = res
    if (code === 0) {
      dataSource.value = data.data
      pagination.total = data.total
    } else {
      message.error(res.message || msg)
    }
  })
}

// 抛出
defineExpose({
  showDrawer
})

</script>

<style scoped>
.tool-view {
  width: 100%;
  display: flex;
  align-items: center;
}
.table-view {
  margin-top: 30px;
}
.tool-title:not(:first-child) {
  margin-left: 16px;
}
.operation-item {
  margin-right: 8px;
}
</style>